import axios from 'axios'
// import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'
import Path from '@/path/index.js'

const path = Path.PATH

const state = {
    projects: [],
    prizeRules: []
}

const getters = {
    allProjects: (state) => {
        return state.projects
    },
    prizeRules: (state) => {
        return state.prizeRules
    }
}

const actions = {
    async fetchProjects({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/project/allProjects', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchProjectsByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/project/getProjectsByWorkspaceId/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchSendProjects({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/project/getSendMessageProjects', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchSendProjectsByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/project/getSendMessageProjectsByWorkspaceId/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchForwardProjects({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/project/getForwardMessageProjects', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchForwardProjectsByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/project/getForwardMessageProjectsByWorkspaceId/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchPrizeProjects({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/project/getPrizeProjects', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchPrizeProjectsByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/project/getPrizeProjectsByWorkspaceId/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setProjects', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchPrizeRules({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/project/allPrizeRules', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setPrizeRules', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchPrizeRulesByWorkspaceId({ commit, rootState }, id) {
        try {
            const response = await axios.get(path + '/project/getPrizeRulesByWorkspace/' + id, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setPrizeRules', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async addProject({ commit, rootState }, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.post(path + '/project/addProject', {
                name: payload.name,
                description: payload.description,
                projectType: payload.projectType,
                sender: payload.sender,
                activeFrom: payload.activeFrom,
                activeTo: payload.activeTo,
                providerId: payload.outgoingProviderId,
                internationalProviderId: payload.forwardingProviderId,
                workspaceId: payload.workspaceId,
                credit: payload.credit,
                international: payload.international
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('newProject', response.data)
            loading.close()
            Dialog.alert({
                title: 'Success',
                message: 'The project was successfully added.',
                confirmText: 'Ok!',
                type: 'is-success',
                hasIcon: true,
                iconPack: 'fa'
            })
        } catch (error) {
            // console.log(error)
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    addProjectPattern({ commit, rootState }, payload) {
        var loading = Loading.open()
        axios.post(path + '/project/addProject', {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        .then(function (response) {
            axios.post(path + '/project/addPrizeRule', {
                pattern: payload.pattern,
                codes: [],
                positiveReply: payload.positiveReply,
                negativeReply: payload.negativeReply,
                projectId: response.data._id
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            .then(function (resp) {
                commit('newProject', response.data)
                commit('newPrizeRule', resp.data)
                loading.close()
                Dialog.alert({
                    title: 'Success',
                    message: 'The project was successfully added.',
                    confirmText: 'Ok!',
                    type: 'is-success',
                    hasIcon: true,
                    iconPack: 'fa'
                })
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (err) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to add project.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    addProjectCode({ commit, rootState }, payload) {
        var loading = Loading.open()
        axios.post(path + '/project/addProject', {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        .then(function (response) {
            axios.post(path + '/project/addPrizeRule', {
                pattern: '',
                codes: payload.codes,
                positiveReply: payload.positiveReply,
                negativeReply: payload.negativeReply,
                projectId: response.data._id
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            .then(function (resp) {
                commit('newProject', response.data)
                commit('newPrizeRule', resp.data)
                loading.close()
                Dialog.alert({
                    title: 'Success',
                    message: 'The project was successfully added.',
                    confirmText: 'Ok!',
                    type: 'is-success',
                    hasIcon: true,
                    iconPack: 'fa'
                })
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (err) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to add project.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    addProjectExternal({ commit, rootState }, payload) {
        var loading = Loading.open()
        axios.post(path + '/project/addProject', {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        .then(function (response) {
            axios.post(path + '/project/addPrizeRule', {
                pattern: '',
                codes: [],
                positiveReply: payload.positiveReply,
                negativeReply: payload.negativeReply,
                projectId: response.data._id
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            .then(function (resp) {
                commit('newProject', response.data)
                commit('newPrizeRule', resp.data)
                loading.close()
                Dialog.alert({
                    title: 'Success',
                    message: 'The project was successfully added.',
                    confirmText: 'Ok!',
                    type: 'is-success',
                    hasIcon: true,
                    iconPack: 'fa'
                })
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (err) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to add project.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to add project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    async editProject({commit, rootState}, payload) {
        var loading = Loading.open()
        try {
            const response = await axios.put(`${path}/project/updateProject/${payload._id}`, 
            {
                name: payload.name,
                description: payload.description,
                projectType: payload.projectType,
                sender: payload.sender,
                activeFrom: payload.activeFrom,
                activeTo: payload.activeTo,
                providerId: payload.outgoingProviderId,
                internationalProviderId: payload.forwardingProviderId,
                workspaceId: payload.workspaceId,
                credit: payload.credit,
                international: payload.international
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('updatedProject', response.data)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async editPatternProject({commit, rootState}, payload) {
        var req1 = axios.put(`${path}/project/updateProject/${payload._id}`, 
        {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var req2 = axios.put(`${path}/project/updatePrizeRule/${payload.ruleId}`, 
        {
            pattern: payload.pattern,
            codes: [],
            positiveReply: payload.positiveReply,
            negativeReply: payload.negativeReply,
            projectId: payload._id
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var loading = Loading.open()
        Promise.all([req1, req2]).then(values => {
            // console.log(values, '3 not null')
            commit('updatedProject', values[0].data)
            commit('updatedPrizeRule', values[1].data)
            loading.close()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    async editCodeProject({commit, rootState}, payload) {
        var req1 = axios.put(`${path}/project/updateProject/${payload._id}`, 
        {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var req2 = axios.put(`${path}/project/updatePrizeRule/${payload.ruleId}`, 
        {
            pattern: '',
            codes: payload.codes,
            positiveReply: payload.positiveReply,
            negativeReply: payload.negativeReply,
            projectId: payload._id
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var loading = Loading.open()
        Promise.all([req1, req2]).then(values => {
            // console.log(values, '3 not null')
            commit('updatedProject', values[0].data)
            commit('updatedPrizeRule', values[1].data)
            loading.close()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    async editExternalProject({commit, rootState}, payload) {
        var req1 = axios.put(`${path}/project/updateProject/${payload._id}`, 
        {
            name: payload.name,
            description: payload.description,
            projectType: payload.projectType,
            sender: payload.sender,
            activeFrom: payload.activeFrom,
            activeTo: payload.activeTo,
            providerId: payload.outgoingProviderId,
            internationalProviderId: payload.forwardingProviderId,
            workspaceId: payload.workspaceId,
            credit: payload.credit,
            international: payload.international
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var req2 = axios.put(`${path}/project/updatePrizeRule/${payload.ruleId}`, 
        {
            pattern: '',
            codes: [],
            positiveReply: payload.positiveReply,
            negativeReply: payload.negativeReply,
            projectId: payload._id
        }, {
            headers: { 'Authorization': rootState.user.authHeader }
        })
        var loading = Loading.open()
        Promise.all([req1, req2]).then(values => {
            // console.log(values, '3 not null')
            commit('updatedProject', values[0].data)
            commit('updatedPrizeRule', values[1].data)
            loading.close()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(function (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        })
    },
    async deleteProject({commit, rootState}, id) {
        var loading = Loading.open()
        try {
            await axios.delete(`${path}/project/deleteProject/${id}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('delProject', id)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to delete project.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    }
}

const mutations = {
    setProjects: (state, projects) => (state.projects = projects ),
    newProject: (state, project) => (state.projects.push(project)),
    delProject: (state, id) => (state.projects = state.projects.filter(prj => prj._id !== id)),
    updatedProject: (state, updProject) => {
        const index = state.projects.findIndex(prj => prj._id === updProject._id)
        if (index !== -1) {
            state.projects.splice(index, 1, updProject)
        }
    },
    newPrizeRule: (state, rule) => (state.prizeRules.push(rule)),
    setPrizeRules: (state, rules) => (state.prizeRules = rules),
    updatedPrizeRule: (state, updRule) => {
        const index = state.prizeRules.findIndex(pr => pr._id === updRule._id)
        if (index !== -1) {
            state.prizeRules.splice(index, 1, updRule)
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}