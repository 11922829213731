import axios from 'axios'
import Path from '@/path/index.js'

const path = Path.PATH

const state = {
    sessions: []
}

const getters = {
    allSessions: (state) => {
        return state.sessions
    }
}

const actions = {
    async getSessionsByUsername({commit, rootState}, username) {
        try {
            const response = await axios.get(`${path}/session/getSessionsByUsername/${username}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setSessions', response.data)
        } catch (error) {
            this.globalLogger('Get sessions by username', error)
        }
    }  
}

const mutations = {
    setSessions: (state, sessions) => (state.sessions = sessions)
}

export default {
    state,
    getters,
    actions,
    mutations
}