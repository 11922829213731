<template>
    <section>
      <div class="container.is-widescreen" style="padding-top:18px">
        <div v-if="this.getRole === 'Super Admin'">
            <h1 class="title" style="color:#3e8ed0">User sessions</h1>
            <h4 class="subtitle">See all sessions of a user.</h4>

            <b-field style="width: 25%;" label="Username:">
          <b-select v-model="username" expanded>
              <option v-for="(value, key) in allUsers" :key="key" :value="value.username">
                {{ value.username }}
              </option>
            </b-select>
        </b-field>
          
            <div style="width: 50%;">
    <b-table :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :pagination-order="paginationOrder"
            :mobile-cards="false" :data="allSessions.length > 0 ? allSessions : []" striped>

          <b-table-column field="username" label="Username" centered  v-slot="props">
            {{props.row.username}}
          </b-table-column>
          <b-table-column field="startTime" label="Start time" centered  v-slot="props">
            {{formatDateTime(props.row.startTime)}}
          </b-table-column>
          <b-table-column field="endTime" label="End time" centered  v-slot="props">
            {{formatDateTime(props.row.endTime)}}
          </b-table-column>
      </b-table>
</div>
</div>
</div>

    </section>
  </template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
  
  export default {
    name: 'UserSessions',
    computed: mapGetters(['getRole', 'allSessions', 'allUsers', 'getSessionId']),
    created() {
      if (this.getRole === 'Super Admin') {
        this.fetchUsers()
      }
    },
    mounted: function () {
      this.updateSession(this.getSessionId)
    },
    watch: {
      username() {
        this.getSessionsByUsername(this.username)
        this.updateSession(this.getSessionId)
      }
    },
    data () {
      return {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        currentPage: 1,
        perPage: 10,
        paginationOrder: '',
        username: null
      }
    },
    methods: {
      ...mapActions(['getSessionsByUsername', 'fetchUsers', 'updateSession']),
      formatDateTime (dateTimeString) {
        let dateTimeArray = dateTimeString.split('T')
        let dateStringInitial = dateTimeArray[0]
        let timeStringInitial = dateTimeArray[1]
        let timeString = timeStringInitial.substring(0,8)
        let dateArrayInitial = dateStringInitial.split('-')
        let dateString = dateArrayInitial[2] + '.' + dateArrayInitial[1] + '.' + dateArrayInitial[0]
        let finalDateTime = timeString + ' ' + dateString
        return finalDateTime
      },
    }
  }
</script>

<style>
</style>