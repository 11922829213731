<template>
    <section>
      <div v-if="getRole !== 'Analytics'" class="container.is-widescreen" style="padding-top:18px">
<div>
            <h1 class="title" style="color:#3e8ed0">All projects</h1>
            <h4 class="subtitle">See and edit existing projects.</h4>

            <div class="col" v-if="getRole === 'Super Admin'" style="width: 18%; padding-bottom: 10px">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
            <option value="all">All</option>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
          
            <div style="padding-right: 20px;">
    <b-table :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :pagination-order="paginationOrder"
            :mobile-cards="false" :data="allProjects.length > 0 ? allProjects : []" striped>

          <b-table-column field="name" label="Project name" centered  v-slot="props">
            {{props.row.name}}
          </b-table-column>
          <b-table-column v-if="getRole === 'Super Admin'" field="workspaceId" label="Workspace" centered  v-slot="props">
            {{getWorkspaceName(props.row.workspaceId)}}
          </b-table-column>
          <b-table-column field="projectType" label="Project type" centered  v-slot="props">
            <!--{{projectTypes[props.row.projectType]}}-->
            {{ props.row.projectType }}
          </b-table-column>
          <b-table-column field="sender" label="Sender" centered  v-slot="props">
            {{props.row.sender}}
          </b-table-column>
          <b-table-column field="active" label="Active from - to" centered  v-slot="props">
            {{props.row.activeFrom}} - {{ props.row.activeTo }}
          </b-table-column>
          <b-table-column field="outgoing" label="Provider" centered  v-slot="props">
            {{getProviderName(props.row.providerId)}}
          </b-table-column>
          <b-table-column field="forwarding" label="International provider" centered  v-slot="props">
            {{getProviderName(props.row.internationalProviderId)}}
          </b-table-column>
          <b-table-column field="credits" label="Credits" centered  v-slot="props">
            {{numberWithSpaces(props.row.credit)}}
          </b-table-column>

          <b-table-column label="Edit" centered v-slot="props">
            <div style="cursor: pointer" @click="editProj(props.row)"><img src="../../assets/edit.png" 
            width="25"/></div>
          </b-table-column>
          <b-table-column label="Delete" centered v-slot="props">
            <div :style="getRole === 'Super Admin' ? 'cursor: pointer' : 'cursor: not-allowed'" @click="delProj(props.row)"><img src="../../assets/delete.png" 
            width="25"/></div>
          </b-table-column>
      </b-table>
</div>
        </div>
      </div>
    </section>
  </template>
  
<script>
  import { mapGetters, mapActions } from 'vuex'
  import EditProject from '@/components/EditProject.vue'

  export default {
    name: 'AllProjects',
    computed: mapGetters(['allProjects', 'projectTypes', 'providers', 'allWorkspaces', 'prizeRules', 'getRole', 'getWorkspaceId', 'getSessionId']),
    created() {
      if (this.getRole !== 'Analytics') {
        if (this.getRole !== 'Super Admin') {
          this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
          this.fetchPrizeRulesByWorkspaceId(this.getWorkspaceId)
        } else {
          this.fetchProjects()
          this.fetchPrizeRules()
          this.fetchWorkspaces()
        }
        this.fetchProjectTypes()
        this.fetchProviders()
      }
    },
    mounted() {
      this.updateSession(this.getSessionId)
    },
    data () {
      return {
        workspaceId: 'all',
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        currentPage: 1,
        perPage: 10,
        paginationOrder: ''
      }
    },
    watch: {
      async workspaceId(newValue) {
        var loadingComponent = this.$buefy.loading.open()
        if (newValue !== 'all') {
          await this.fetchProjectsByWorkspaceId(newValue)
          loadingComponent.close()
        } else {
          await this.fetchProjects()
          loadingComponent.close()
        }
      }
    },
    methods: {
      ...mapActions(['fetchProjects', 'deleteProject', 'fetchProjectTypes', 'fetchProviders', 'fetchWorkspaces',
        'fetchProjectsByWorkspaceId', 'fetchPrizeRulesByWorkspaceId', 'fetchPrizeRules', 'updateSession']),
      editProj(proj) {
        this.updateSession(this.getSessionId)
        if (proj.projectType === 'Prize Pattern') {
          proj.positiveReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].positiveReply
          proj.negativeReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].negativeReply
          proj.pattern = this.prizeRules.filter(x => x.projectId === proj._id)[0].pattern
          proj.ruleId = this.prizeRules.filter(x => x.projectId === proj._id)[0]._id
        } else if (proj.projectType === 'Prize Code') {
          proj.positiveReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].positiveReply
          proj.negativeReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].negativeReply
          proj.codes = this.prizeRules.filter(x => x.projectId === proj._id)[0].codes
          proj.ruleId = this.prizeRules.filter(x => x.projectId === proj._id)[0]._id
        } else if (proj.projectType === 'Prize External') {
          proj.positiveReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].positiveReply
          proj.negativeReply = this.prizeRules.filter(x => x.projectId === proj._id)[0].negativeReply
          proj.ruleId = this.prizeRules.filter(x => x.projectId === proj._id)[0]._id
        }
        this.$buefy.modal.open({
          component: EditProject,
          parent: this,
          props: { projectToEdit: proj },
          hasModalCard: true,
          trapFocus: true,
          canCancel: ['x']
        })
      },
      delProj(proj) {
        this.updateSession(this.getSessionId)
        if (this.getRole === 'Super Admin') {
          this.$buefy.dialog.confirm({
            message: 'Are you sure that you want to <b>delete</b> project "' + proj.name + '"?',
            confirmText: 'Yes',
            type: 'is-danger',
            onConfirm: () => {
              this.deleteProject(proj._id)
              this.updateSession(this.getSessionId)
            }
          })
        }
      },
      getProviderName (id) {
        for (let i = 0; i < this.providers.length; i++) {
          if (this.providers[i]['_id'] === id) {
            return this.providers[i].name;
          }
        }
        return '/';
      },
      getWorkspaceName (id) {
        for (let i = 0; i < this.allWorkspaces.length; i++) {
          if (this.allWorkspaces[i]['_id'] === id) {
            return this.allWorkspaces[i].name;
          }
        }
        return -1;
      },
      numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }
  }
</script>

<style>
</style>