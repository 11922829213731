import axios from 'axios'
// import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'
import Path from '@/path/index.js'

const path = Path.PATH

let loading
let loadingNew

const state = {
    messages: []
}

const getters = {
    allMessages: (state) => {
        return state.messages
    }
}

const actions = {
    async fetchMessages({ commit, rootState }) {
        try {
            const response = await axios.get(path + '/message/allMessages', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setMessages', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async addMessage({ commit, rootState }, payload) {
        if (payload.currentCall === 1) {
            loading = Loading.open()
        }
        try {
            const response = await axios.post(path + '/message/sendMessage', {
                text: payload.text,
                receiver: payload.receiver,
                projectId: payload.projectId,
                size: payload.size
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('newMessage', response.data)
            if (payload.currentCall === payload.noOfCalls) {
                loading.close()
                Dialog.alert({
                    title: 'Success',
                    message: 'The message was successfully sent.',
                    confirmText: 'Ok!',
                    type: 'is-success',
                    hasIcon: true,
                    iconPack: 'fa'
                })
            }
        } catch (error) {
            if (payload.currentCall === payload.noOfCalls) {
                loading.close()
                Dialog.alert({
                    title: 'Success',
                    message: 'The message was successfully sent.',
                    confirmText: 'Ok!',
                    type: 'is-success',
                    hasIcon: true,
                    iconPack: 'fa'
                })
            }
            /* loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to send message.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            }) */
        }
    },
    async addSingleMessage({ commit, rootState }, payload) {
        loadingNew = Loading.open()
        try {
            const response = await axios.post(path + '/message/sendMessage', {
                text: payload.text,
                receiver: payload.receiver,
                projectId: payload.projectId,
                size: payload.size
            }, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('newMessage', response.data)
            loadingNew.close()
            Dialog.alert({
                title: 'Success',
                message: 'The message was successfully sent.',
                confirmText: 'Ok!',
                type: 'is-success',
                hasIcon: true,
                iconPack: 'fa'
            })
        } catch (error) {
            loadingNew.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to send message.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    }
}

const mutations = {
    setMessages: (state, messages) => (state.messages = messages),
    newMessage: (state, message) => (state.messages.push(message))
}

export default {
    state,
    getters,
    actions,
    mutations
}