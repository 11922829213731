const state = {
    username: null,
    password: null,
    authHeader: null,
    role: null,
    workspaceId: null,
    sessionId: null
}

const getters = {
    getUsername: (state) => {
        return state.username
    },
    getPassword: (state) => {
        return state.password
    },
    getAuthHeader: (state) => {
        return state.authHeader
    },
    getRole: (state) => {
        return state.role
    },
    getWorkspaceId: (state) => {
        return state.workspaceId
    },
    getSessionId: (state) => {
        return state.sessionId
    }
}

const actions = {
    setUsername({commit}, username) {
        commit('setUsrname', username)
    },
    setPassword({commit}, password) {
        commit('setPass', password)
    },
    setAuthHeader({commit}, encStr) {
        commit('setHead', encStr)
    },
    setRole({commit}, role) {
        commit('setRl', role)
    },
    setWorkspaceId({commit}, workspaceId) {
        commit('setWId', workspaceId)
    },
    setSessionId({commit}, sessionId) {
        commit('setSId', sessionId)
    }
}

const mutations = {
    setUsrname: (state, username) => (state.username = username),
    setPass: (state, password) => (state.password = password),
    setHead: (state, encStr) => (state.authHeader = encStr),
    setRl: (state, role) => (state.role = role),
    setWId: (state, workspaceId) => (state.workspaceId = workspaceId),
    setSId: (state, sessionId) => (state.sessionId = sessionId)
}

export default {
    state,
    getters,
    actions,
    mutations
}