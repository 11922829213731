import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import vuetify from './plugins/vuetify'
import yearPicker from 'vue-year-picker'
import JsonExcel from "vue-json-excel"
import { DialogProgrammatic } from 'buefy'

Vue.component("downloadExcel", JsonExcel)

require('@/assets/main.scss')
// import JsonCSV from 'vue-json-csv'

Vue.use(Buefy)
Vue.use(yearPicker)
// Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const currentUser = (store.state.user.username && localStorage.getItem('loggedIn'))
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && currentUser === null) next('login')
  else if (!requiresAuth && currentUser !== null) next('home')
  else next()
})

Vue.mixin({
  methods: {
    // eslint-disable-next-line
    globalLogger: function (logText1, logText2) {
      // console.log(logText1, logText2)
    },
    setupTimers: function () {
      document.addEventListener("keypress", resetTimer, false);
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("touchmove", resetTimer, false);
      startTimer();
    }
  }
})

const timeoutInMS = 60 * 60 * 1000; // 1 hour
let timeoutId;
  
function handleInactive() {
  // if (localStorage.getItem('loggedIn')) {
  if (store.state.user.username) {
    store.state.user.username = null
    store.state.user.password = null
    store.state.user.authHeader = null
    store.state.user.role = null
    store.state.user.workspaceId = null
    store.state.user.sessionId = null
    localStorage.removeItem('loggedIn')
    DialogProgrammatic.alert({
      title: 'Session expired',
      message: 'You have been logged out. Log in again to continue.',
      confirmText: 'Ok',
      onConfirm: () => {
        router.replace({ name: 'login' })
      }
    })
  }
}

function startTimer() { 
    // setTimeout returns an ID (can be used to start or clear a timer)
    timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() { 
  clearTimeout(timeoutId);
  if (store.state.user.username) {
    startTimer();
  }
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
