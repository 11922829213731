<template>
    <section>
      <div class="container.is-widescreen" style="padding:18px">

            <h1 class="title" style="color:#3e8ed0">Analytics</h1>
            <h4 class="subtitle">No. of sent messages by types.</h4>
          
          <div class="columns" style="padding-left: 14px; padding-top: 10px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">
              <b-field label="Project:">
                <b-select v-model="projectId" expanded>
                  <option v-if="getRole === 'Super Admin'" value="all">All</option>
              <option v-for="(value, key) in showProjects" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
    </b-field>
  </div>
<div style="padding-right: 20px;">
            <b-field label="Date range:">
                <b-datepicker
                v-model="dates"
                :locale="locale"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus range>
            </b-datepicker>
    </b-field>
  </div>
  <div style="color: white">
 <b-field label="/" class="foo"><b-button type="is-primary" @click="searchClicked">Search</b-button></b-field>
</div>
  
        </div>
        <div style="width: 40%;" v-if="showData">
        <b-table :data="tableData" :columns="columnsC" :bordered="isBordered" class="equalWidth"></b-table>
    </div>

        <div>
  <div class="columns" style="padding-left: 14px; padding-top: 30px; padding-bottom: 15px;">
            <div style="padding-right: 20px;">

              <b-dropdown :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                v-if="showData"
                    label="Export data"
                    type="is-info"
                    icon-right="menu-down" />
            </template>

            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByType.csv" type="csv" :escapeCsv="falseString">
  CSV
</download-excel>
</b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <download-excel v-if="showData" :data="tableData" :fields="jsonFields" name="AnalyticsByType.xls">
  Excel
</download-excel>
</b-dropdown-item>
        </b-dropdown>

            </div>
            </div>
</div>
  </div>
    </section>
  </template>
    <!--</div>
    </div>
    </section>
  </template>-->
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import axios from 'axios'
  // import VueJsonToCsv from 'vue-json-to-csv'
  import Path from '@/path/index.js'

  export default {
    name: 'ByType',
    computed: mapGetters(['allProjects', 'getRole', 'getWorkspaceId', 'getAuthHeader', 'getSessionId']),
    created: function () {
      if (this.getRole !== 'Super Admin') {
        this.fetchProjectsByWorkspaceId(this.getWorkspaceId)
      } else {
        this.fetchProjects()
      }
      this.showProjects = this.allProjects
      this.showProjects = this.showProjects.filter(x => x.projectType === 'Send Messages' || x.projectType === 'Forward Messages')
    },
    mounted: function () {
      this.updateSession(this.getSessionId)
    },
    data () {
      return {
        falseString: false,
        showProjects: [],
        tableData: null,
        showData: false,
        projectId: null,
        subHeaderMessage: '',
        isBordered: true,
        dates: [],
        locale: 'en-GB',
        // Array will be automatically processed with visualization.arrayToDataTable function
        columnsC: [
            {
                field: 'name',
                label: 'Message type',
                // width: '40',
                // numeric: true
                centered: true
            },
            {
                field: 'value',
                label: 'No. of messages',
                centered: true
            }
        ],
        jsonFields: {
          "Message type": "name",
          "No. of messages": "value"
        }
      }
    },
    methods: {
        ...mapActions(['fetchProjectsByWorkspaceId', 'fetchProjects', 'updateSession']),
        async searchClicked () {
          this.updateSession(this.getSessionId)
          if (this.dates.length !== 2 || this.projectId === null) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please fill in all fields.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
          } else {
            var loadingComponent = this.$buefy.loading.open()
            let startDateOriginal = this.dates[0].toLocaleDateString('en-GB')
            let endDateOriginal = this.dates[1].toLocaleDateString('en-GB')
            var startDateArray = startDateOriginal.split('/')
            var endDateArray = endDateOriginal.split('/')
            var startDate = startDateArray[2] + '-' + startDateArray[1] + '-' + startDateArray[0]
            var endDate = endDateArray[2] + '-' + endDateArray[1] + '-' + endDateArray[0]
            this.globalLogger(startDate, endDate)
            try {
              if (this.projectId !== null && this.projectId !== 'all') {
                  const response = await axios.post(Path.PATH + '/analytics/messagesByTypeProject/' + this.projectId, {
                      startDate: startDate,
                      endDate: endDate
                  }, {
                      headers: { 'Authorization': this.getAuthHeader }
                  })
                  this.tableData = response.data
              } else {
                  const response = await axios.post(Path.PATH + '/analytics/messagesByType', {
                      startDate: startDate,
                      endDate: endDate
                      }, {
                      headers: { 'Authorization': this.getAuthHeader }
                  })
                  this.tableData = response.data
              }
              loadingComponent.close()
              this.showData = true
            } catch (error) {
              loadingComponent.close()
              this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'An error occured while trying to fetch data.',
                  confirmText: 'Ok!',
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa'
              })
            }
          }
        }
    }
  }
</script>

<style>
.foo > .label { color: white }
.equalWidth {
    width: 100%;
    display: table;
    table-layout: fixed; /* optional, for equal spacing */
    border-collapse: collapse;
}
</style>