<template>
    <div class="modal-card" ref="element">
        <!--style="width: auto">-->
        <header class="modal-card-head">
            <p class="modal-card-title">Edit workspace</p>
        </header>
        <section class="modal-card-body">
            <div class="container">
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Name:">
            <b-input v-model="name"></b-input>
        </b-field>
    </div>
   </div>
   </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-primary" @click="editWrks">Submit</b-button>
            <b-button type="is-light" @click="$emit('close')">Close</b-button>
        </footer>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'EditWorkspace',
    props: ['workspaceToEdit'],
    computed: mapGetters(['getSessionId']),
    data: () => {
        return {
            name: null
        }
    },
    mounted() {
        this.name = this.workspaceToEdit.name
    },
    methods: {
        ...mapActions(['editWorkspace', 'updateSession']),
        editWrks () {
            if (this.name.trim() !== '') {
                var payload = {
                    _id: this.workspaceToEdit._id,
                    name: this.name
                }
                this.editWorkspace(payload)
                this.$emit('close')
                this.updateSession(this.getSessionId)
            } else {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Please enter a name.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            }
        }
    }
}
</script>
