<template>
    <div class="modal-card" ref="element" style="width: 1100px">
        <!--style="width: auto">-->
        <header class="modal-card-head">
            <p class="modal-card-title">Edit user</p>
        </header>
        <section class="modal-card-body">
            <div class="container">
  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Username:">
            <b-input v-model="username"></b-input>
        </b-field>
    </div>
    <div class="col" v-if="getRole === 'Super Admin' && role !== 'Super Admin'">
        <b-field label="Workspace:">
          <b-select v-model="workspaceId" expanded>
              <option v-for="(value, key) in allWorkspaces" :key="key" :value="value._id">
                {{ value.name }}
              </option>
            </b-select>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Email:">
            <b-input v-model="email"></b-input>
        </b-field>
    </div>
    <div class="col" v-if="getRole === 'Super Admin'">
        <b-field  label="Role:">
      <b-select v-model="role" expanded>
              <option v-for="(value, key) in roles" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
    </b-field>
    </div>
  </div>

  <div class="row" style="padding-bottom: 20px;">
    <div class="col">
        <b-field label="Name:">
            <b-input v-model="name"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Surname:">
            <b-input v-model="surname"></b-input>
        </b-field>
    </div>
    <div class="col">
        <b-field label="Address:">
            <b-input v-model="address"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Phone:">
            <b-input v-model="phone"></b-input>
    </b-field>
    </div>
    <div class="col">
        <b-field label="Status">
          <b-select v-model="status" expanded>
            <option v-for="(value, key) in this.statuses" :key="key" :value="value">
                {{ value }}
              </option>
            </b-select>
        </b-field>
    </div>
  </div>
</div>
            
        </section>
        <footer class="modal-card-foot">
            <b-button @click="editUsr" type="is-primary" :disabled="getRole === 'User'">Submit</b-button>
            <b-button type="is-light" @click="$emit('close')">Close</b-button>
        </footer>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'EditUser',
    props: ['userToEdit'],
    computed: mapGetters(['roles', 'statuses', 'allWorkspaces', 'getRole', 'getSessionId']),
    created: function () {
      this.fetchStatuses()
      if (this.getRole === 'Super Admin') {
        this.fetchWorkspaces()
        this.fetchRoles()
      }
    },
    data: () => {
        return {
            username: null,
            email: null,
            role: null,
            name: null,
            surname: null,
            address: null,
            phone: null,
            status: null,
            workspaceId: null
        }
    },
    mounted() {
        this.username = this.userToEdit.username
        this.email = this.userToEdit.email
        this.role = this.userToEdit.role
        this.name = this.userToEdit.name
        this.surname = this.userToEdit.surname
        this.address = this.userToEdit.address
        this.phone = this.userToEdit.phone
        this.status = this.userToEdit.status
        this.workspaceId = this.userToEdit.workspaceId
    },
    methods: {
        ...mapActions(['editUser', 'fetchRoles', 'fetchStatuses', 'fetchWorkspaces', 'updateSession']),
        editUsr () {
            if (this.getRole !== 'User') {
                if (this.checkData()) {
                    this.showAlert()
                } else {
                    var payload = {
                        _id: this.userToEdit._id,
                        username: this.username,
                        password: this.userToEdit.password,
                        email: this.email,
                        role: this.role,
                        name: this.name,
                        surname: this.surname,
                        address: this.address,
                        phone: this.phone,
                        status: this.status,
                        workspaceId: this.workspaceId
                    }
                    if (this.getRole === 'Super Admin' && this.role === 'Super Admin') {
                        payload.workspaceId = '1'
                    }
                    this.editUser(payload)
                    this.$emit('close')
                    this.updateSession(this.getSessionId)
                }
            }
        },
        checkData () {
            return this.username.trim() === '' || this.email.trim() === '' || this.name.trim() === '' || this.address.trim() === '' || this.surname.trim() === ''
            || (this.getRole === 'Super Admin' && this.role !== 'Super Admin' && this.workspaceId === '1') || this.phone.trim() === '' 
        },
        showAlert () {
            this.$buefy.dialog.alert({
            title: 'Error',
            message: 'All fields are required.',
            confirmText: 'Ok!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa'
          })
       }
    }
}
</script>
